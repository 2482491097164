<template>
  <div class="stencilRight">
    <div class="header">
      <div>最热模板推荐</div>
    </div>

    <div class="hotList">
      <div
        class="list_item"
        @click="listItemClick(item)"
        v-for="item in hotList"
        :key="item.nodeMd5"
      >
        {{ item.footNotes }}
      </div>
    </div>

    <div class="header">
      <div>最新模板推荐</div>
    </div>

    <div class="hotList">
      <div
        class="list_item"
        @click="listItemClick(item)"
        v-for="item in dateList"
        :key="item.nodeMd5"
      >
        {{ item.footNotes }}
      </div>
    </div>

    <div class="dateList"></div>

    <div class="header">
      <div>功能应用视频</div>
      <!-- <div
        v-if="!showMore"
        class="more"
        @click="$router.push('/videoWritingIndex')"
      >
        了解更多>
      </div> -->
    </div>
    <div class="list">
      <div
        class="item"
        :class="{ width: isflex }"
        v-for="item in videoList"
        :key="item.id"
        @click="viewVideo(item)"
      >
        <div class="img">
          <img :src="url + item.imgUrl" alt="" />
        </div>
        <div class="info">
          <div class="title">{{ item.title }}</div>
          <!-- <dir class="dataInfo">
            <div class="hot">
              <img :src="require('assets/images/writing/heat.png')" alt="" />
              {{ item.hot }}
            </div>
            <div class="score">
              <img :src="require('assets/images/writing/score.png')" alt="" />
              {{ item.star }}
            </div>
            <div class="review">
              <img :src="require('assets/images/writing/review.png')" alt="" />
              {{ item.commentNum }}
            </div>
          </dir> -->
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" :before-close="beforeClose">
      <videoPlayerPage v-if="videoSrc" :imgSrc="imgSrc" :videoSrc="videoSrc" />
    </el-dialog>
  </div>
</template>

<script>
//例如：import  from '';
import { templateSearch } from "api/search";
import qs from "qs";
import { getListById } from "api/videoWriting";
import videoPlayerPage from "components/videoInfoComponents/videoPlayerPage.vue";
export default {
  name: "stencilRight",
  //import引入的组件需要注入到对象中才能使用
  components: {
    videoPlayerPage,
  },
  props: {
    isflex: {},
    showMore: {},
  },
  data() {
    //这里存放数据
    return {
      url: this.$store.state.onlineBasePath,
      videoList: [],
      title: "",
      imgSrc: "",
      videoSrc: "",
      dialogVisible: false,
      dateList: [],
      hotList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    listItemClick(item) {
      console.log(2);
      this.$emit("rightListClick", item);
    },
    handleVideo(img, video) {
      this.imgSrc = img;
      this.videoSrc = video;
      this.dialogVisible = true;
    },

    beforeClose() {
      this.imgSrc = "";
      this.videoSrc = "";
      this.dialogVisible = false;
    },

    //获取推荐列表
    async getVideoList() {
      const params = {
        page: 1,
        limit: 10,
        parentId: "",
        title: this.title,
        timeSort: "",
        hotSort: "",
        starSort: "",
        commentSort: "",
      };

      const res = await getListById(params);
      this.videoList = res.data.data;
      // this.total = res.data.total;
    },
    viewVideo(item) {
      this.handleVideo(this.url + item.imgUrl, this.url + item.videoUrl);
    },

    async getMuBanList(parameter) {
      const data = {
        page: 1,
        limit: 5,
        key: "",
        labelId: "MBgongwxz00001",
        pageUuid: "",
        findField: 2,
        sortType: parameter ? "create_time" : "hot",
        sort: "desc",
      };

      const res = await templateSearch(qs.stringify(data));
      if (res.code == 200) {
        parameter
          ? (this.dateList = res.data.data)
          : (this.hotList = res.data.data);
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getMuBanList(1);
    this.getMuBanList("");
    switch (this.$route.path) {
      case "/recommendExperience":
        this.title = "智能推文";
        break;
      case "/traceSource":
        this.title = "溯源";
        break;
      case "/templatetHtml":
        this.title = "模板";
        break;
      case "/goldenSentence/goldenSentenceIndex":
        this.title = "金句";
        break;
      case "/disk":
        this.title = "智能网盘";
        break;
      case "/checking":
        this.title = "核校";
        break;
      case "/infoCompileIndex":
        this.title = "文库";
        break;
      case "/dataAnalysisIndex":
        this.title = "数据分析";
        break;
      case "/dataCollectionToolBar":
        this.title = "网页采集";
        break;
      case "/ocr":
        this.title = "截图OCR";
        break;
    }
    this.getVideoList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.stencilRight {
  .header {
    margin-top: 35px;
    padding-left: 5px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    border-left: solid 5px #ff6900;
    .more {
      color: #999;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .hotList {
    .list_item {
      padding: 10px 20px;
      line-height: 1.5;
      cursor: pointer;

      &:hover {
        color: #ff6900;
      }
    }
  }
  .list {
    padding-top: 25px;
    .width {
      width: 25%;
    }
    .item {
      cursor: pointer;
      padding: 10px 36px;
      box-sizing: border-box;
      display: flex;

      .img {
        width: 120px;
        img {
          width: 120px;
          height: 80px;
        }
      }
      .info {
        margin-left: 5px;
        .title {
          line-height: 1.5;
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .dataInfo {
          box-sizing: border-box;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding: 0;
          padding-top: 10px;
          > div {
            display: flex;
            align-items: center;
            font-size: 13px;
            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}

.isflex {
  width: 1338px;
  padding: 20px 20px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  margin-bottom: 20px;
  overflow-x: auto;

  .header {
    margin-top: 0;
  }

  .list {
    overflow-x: auto;
    display: flex;
  }
}
</style>
