<template>
  <div class="outBox">
    <div class="title_text">
      <img :src="require('@/assets/bgc7.png')" alt="" class="banner" />

      <span class="text"> 优质模板 </span>

      <!-- 搜索 -->
      <div class="search_box">
        <div class="search_left">
          <el-select v-model="selectTheme" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <input
          class="inp"
          type="text"
          placeholder="请输入内容搜索"
          v-model="searchValue"
          @keyup.enter="search"
        />
        <div class="search_btn" @click="search()">
          <img :src="require('assets/images/idiom.png')" alt="" />
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>

        <div class="tuijian">
          <div class="tuijian_text">为你推荐:</div>

          <div class="list">
            <div
              class="list_item"
              @click="
                page = 1;
                pageUuid = '';
                isDetails = false;
                sidebarTtemId = item.labelId;
                getMuBanList(item.labelId);
                getBankList(item.labelId);
              "
              v-for="item in tuijianList"
              :key="item.nodeMd5"
            >
              {{ item.conName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stencil">
      <div class="left leftFlex">
        <div class="stencilDetails" v-show="isDetails">
          <stencilDetails
            @findBuyState="findBuyState"
            @backClick="backClick"
            ref="stencilDetails"
            :item="stencilId"
          />
        </div>
        <!-- 占位 -->
        <div class="perch" :class="{ width: isDetails }"></div>
        <!-- 遮罩层 -->
        <div
          class="mask_layer"
          v-if="ChildrenInfoShow"
          @click="ChildrenInfoShow = false"
        ></div>
        <!-- 4级侧边栏 -->
        <div class="sidebar_box" :class="ChildrenInfoShow ? 'disflex' : ''">
          <div
            class="an_btn"
            :class="ChildrenInfoShow ? 'spin' : ''"
            v-show="ChildrenInfo.list.length != 0"
            @click="
              () => {
                ChildrenInfoShow = !ChildrenInfoShow;

                if (ChildrenInfoShow) {
                  if (ChildrenInfo.list.length != 0) {
                    ChildrenInfo.isShow = true;
                  } else if (ChildrenInfo1.list.length != 0) {
                    ChildrenInfo1.isShow = true;
                  } else if (ChildrenInfo2.list.length != 0) {
                    ChildrenInfo2.isShow = true;
                  }
                }
              }
            "
          >
            <i class="el-icon-arrow-right"></i>
          </div>

          <!-- 第一级 -->
          <div class="sidebar">
            <el-menu ref="elMenu" unique-opened :collapse-transition="false">
              <el-menu-item index="-2" @click="handleAllClick">
                全部
              </el-menu-item>
              <el-submenu index="zz">
                <template slot="title"> 收藏/已购买模板 </template>
                <el-menu-item index="-1" @click="handleSCClick">
                  收藏模板
                </el-menu-item>
                <el-menu-item index="-3" @click="handlebuyClick">
                  购买模板
                </el-menu-item>
              </el-submenu>

              <el-submenu
                :index="item.labelId"
                v-for="(item, index) in bankList"
                @click.native="
                  () => {
                    isMark = false;
                    page = 1;
                    ChildrenInfoShow = false;
                    sidebarTtemId = item.labelId;
                    ChildrenInfo.isShow = false;
                    $refs.elMenu.activeIndex = null;
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                    stair = item.labelId;
                    currentClickItemVip = item;
                    getChildrenList(item, 2);
                  }
                "
                :class="[
                  item.contentNum == 0 ? 'charNumClass' : '',
                  sidebarTtemId == item.labelId ? 'pitchOn' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                :key="item.id"
                :title="item.conName.length > 9 ? item.conName : ''"
              >
                <template slot="title">
                  {{ item.conName }}
                  <el-tooltip
                    effect="light"
                    :content="item.remark"
                    placement="right"
                    popper-class="custom-popper"
                  >
                    <img
                      class="vipIcon"
                      v-if="item.isVip != 1"
                      :src="
                        item.isVip == 2
                          ? require('assets/images/headers/vip.png')
                          : item.isVip == 3
                          ? require('assets/images/headers/svip.png')
                          : item.isVip == 4
                          ? require('assets/images/ban.png')
                          : ''
                      "
                      @click="memberRenewal(item, $event)"
                      alt=""
                  /></el-tooltip>
                  <img
                    v-if="item.labelTip != 0 && item.labelTip != null"
                    :src="
                      item.labelTip == 1
                        ? require('assets/images/hot1.png')
                        : item.labelTip == 2
                        ? require('assets/images/j.png')
                        : require('assets/images/new1.png')
                    "
                    alt=""
                  />
                </template>
                <el-menu-item
                  :index="index + '-' + index1"
                  v-for="(item1, index1) in item.children"
                  :key="item1.id"
                  :title="item1.conName.length > 8 ? item1.conName : ''"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  @click.native.stop="
                    () => {
                      parentName = item1.conName;
                      isMark = false;
                      page = 1;

                      if (ChildrenInfo.list.length != 0) {
                        ChildrenInfo.isShow = true;
                      }
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getChildrenList(item1, 1);
                    }
                  "
                >
                  <!-- <img class="img1" :src="$store.state.onlineBasePath + item1.iconImg" alt="" /> -->
                  {{ item1.conName
                  }}<img
                    v-if="item1.checked == 2"
                    class="img2"
                    :src="require('assets/images/gouwuche1.png')"
                    @click="shoppCar(item1.productId)"
                    alt=""
                  />
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第二级 -->
          <div
            class="sidebar"
            :class="ChildrenInfoShow ? '' : 'absolute'"
            v-show="ChildrenInfo.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              unique-opened
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo.isShow = false;
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo.parentName }}
              </el-menu-item>
              <el-submenu
                :index="item.labelId"
                v-for="item in ChildrenInfo.list"
                @click.native="
                  () => {
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                    getChildrenList(item, 2);
                  }
                "
                :class="[
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                :key="item.id"
                :title="item.conName.length > 7 ? item.conName : ''"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getChildrenList(item1, 3);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第三级 -->
          <div
            class="sidebar"
            :class="ChildrenInfoShow ? '' : 'absolute'"
            v-show="ChildrenInfo1.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              unique-opened
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo1.parentName }}
              </el-menu-item>
              <el-submenu
                :index="item.labelId"
                v-for="item in ChildrenInfo1.list"
                :title="item.conName.length > 7 ? item.conName : ''"
                @click.native="
                  () => {
                    ChildrenInfo2.isShow = false;
                    getChildrenList(item, 2);
                  }
                "
                :class="[
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                :key="item.id"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getChildrenList(item1, 4);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第四级 -->
          <div
            class="sidebar"
            :class="ChildrenInfoShow ? '' : 'absolute'"
            v-show="ChildrenInfo2.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              unique-opened
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo2.parentName }}
              </el-menu-item>
              <el-submenu
                :index="item.labelId"
                v-for="item in ChildrenInfo2.list"
                :title="item.conName.length > 7 ? item.conName : ''"
                @click.native="
                  () => {
                    getChildrenList(item, 2);
                  }
                "
                :class="[
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                :key="item.id"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      getChildrenList(item1, 5);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>

        <!-- 主体区域 -->
        <div class="main" id="main" v-show="!isDetails">
          <!-- <div class="banner">
            <div class="item" @click="goMyWork">
              <img :src="require('assets/images/cupload1.png')" alt="" />
              版权作品投稿
            </div>
            <div class="item" @click="goWorksRank">
              <img :src="require('assets/images/rank1.png')" alt="" />
              作品排名
            </div>
            <div class="item" @click="goAuthorRank">
              <img :src="require('assets/images/jiangb1.png')" alt="" />
              作者排名
            </div>
            <div class="item" @click="goMyAttention">
              <img :src="require('assets/images/author_f1.png')" alt="" />
              关注作者
            </div>
          </div> -->

          <!-- 排序 -->
          <div class="sort_box">
            <div
              class="right-item"
              v-for="(item, index) in sortArr"
              :key="index"
              @click="sortClick(item)"
              v-show="!isMark"
            >
              <div
                class="right-item-text"
                :class="item.colorSwitch == true ? 'fontColor' : ''"
              >
                {{ item.title }}
              </div>
              <i
                class="right-item-icon"
                :class="item.sort == true ? 'up' : 'down'"
              ></i>
            </div>
            <!-- <el-upload
              class="upload-demo"
              action=""
              :show-file-list="false"
              :http-request="importChecking"
            >
              <div class="right-item">
                <i class="left-item-icon download"></i>
                <div class="right-item-text">上传文件</div>
              </div>
            </el-upload>
            <div class="right-item" @click="goDisk">
              <i class="left-item-icon folder"></i>
              <div class="right-item-text">打开文件夹</div>
            </div> -->
          </div>

          <!-- 模板列表 -->
          <div class="stencil_list">
            <div
              class="stencil_item"
              @click="stencilClick(v)"
              v-for="(v, i) in listData"
              :key="i"
            >
              <img class="img" :src="require('assets/images/wps.png')" alt="" />

              <div class="content">
                <div class="title">{{ v.footNotes }}</div>
                <div class="obj">
                  <div class="date">上传: {{ v.publishTime }}</div>
                  <div class="hot">热度: {{ v.hot }}</div>
                  <div class="size">
                    <!-- 评分: {{ v.star.toFixed(1) }} -->
                  </div>
                </div>
              </div>

              <!-- <div class="hover_box animate__animated animate__bounceInRight">
                <div class="vip">{{ remark || "免费" }}</div>
              </div> -->

              <img
                class="imgIcon"
                @click.stop="collectionClick(v)"
                :src="
                  v.isMark == true
                    ? require('assets/images/goldenSentence/collection-red.png')
                    : require('assets/images/goldenSentence/collection.png')
                "
                alt=""
              />
            </div>
          </div>

          <!-- 空 -->
          <div class="kong" v-if="listData.length == 0">
            <img :src="require('assets/images/kong.png')" alt="" />

            <div class="txt">{{ noText }}</div>
          </div>

          <el-pagination
            v-if="listData.length != 0"
            style="margin-left: 20px"
            layout="prev, pager, next"
            :page-sizes="[15, 20, 25, 30]"
            :pager-count="5"
            :current-page="page"
            :page-size="limit"
            @current-change="currentChange"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>

      <div class="right recommendRightList">
        <!-- <recommend /> -->
        <stencilRight @rightListClick="rightListClick" />
      </div>
      <el-dialog
        v-dialogDrag
        title="上传成功"
        center
        :visible.sync="uploadSuccess"
      >
        <div style="text-align: center">
          文件已经保存在 <span style="color: #409eff">智能网盘</span>下的
          <span style="color: #409eff">系统文件/收藏文档/收藏模板</span
          >中,可随时去查看
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="goDisk">现在查看</el-button>
          <el-button @click="uploadSuccess = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getProductListGroup,
  gettemplateAndSayingLibrary,
  getlibrarylist,
} from "api/dropDownList";
import commonFooter from "components/footer/commonFooter.vue";
import recommend from "components/recommend/recommend.vue";
// import WorksRank from "views/library/WorksRank.vue";
// import AuthorRank from "views/library/AuthorRank.vue";
// import MyAttention from "views/library/MyAttention.vue";
import stencilDetails from "./stencilDetails";
import qs from "qs";
import { previewUrl } from "api/common";
import { getToken } from "utils/auth";
import { debounce } from "utils/utils";
import {
  findOftenLibraryType,
  findOftenWord,
  addTemplateCollect,
  addTemplateHot,
  logAdd,
  templateSearch,
  openTemplateFile,
  findUserCollectTemplate,
  findUserBuyTemplate,
} from "api/search";
import { saveToCollect } from "api/infoCompile";
import stencilRight from "./stencilRight";
export default {
  name: "stencil",
  //import引入的组件需要注入到对象中才能使用
  components: {
    stencilDetails,
    commonFooter,
    // recommend,
    stencilRight,
  },
  data() {
    //这里存放数据
    return {
      isMark: false,
      allremark: "",
      stair: "MBgongwxz00001",
      currentClickItemVip: null,
      noText: "更新中",
      isAn: false,
      ChildrenInfoShow: false,
      uploadSuccess: false,
      ChildrenInfo: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo1: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo2: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      selectTheme: "2",
      options: [
        {
          value: "1",
          label: "全文",
        },
        {
          value: "2",
          label: "标题",
        },
      ],
      pageUuid: "",
      labelId: "",
      // 库列表
      bankList: [],
      sidebarTtemId: -1,
      // 收藏
      collect: [],
      // 需要展开的数组
      openeds: [],
      // 搜索关键字
      searchValue: "",

      // id
      themeId: "",
      libraryId: "",
      // 主题索引
      themeI: -1,
      // 模板列表
      listData: [],
      // 页码
      page: 1,
      limit: 10,
      total: 0,
      isDetails: false,
      stencilId: "",
      remark: "",
      sortArr: [
        //排序数组
        {
          title: "时间",
          sort: false, //true 升序 false降序
          id: 1,
          colorSwitch: true,
        },
        {
          title: "热度",
          sort: true,
          id: 2,
          colorSwitch: false,
        },
        // {
        //   title: "评分",
        //   sort: true,
        //   id: 5,
        // },
        // {
        //   title: "评论",
        //   sort: true,
        //   id: 6,
        // },
      ],
      sortType: 1,
      sortVal: "desc",
      // 推挤
      tuijianList: [
        {
          conName: "公文写作",
          labelId: "MBgongwxz00001",
        },
        {
          conName: "节日庆典",
          labelId: "MBjierqd00001",
        },
        {
          conName: "企业制度",
          labelId: "MBqiyzd00001",
        },
        {
          conName: "活动策划",
          labelId: "MBhuodch00001",
        },
        {
          conName: "商业计划书",
          labelId: "MBshangyjhs00001",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    $route: {
      deep: true,
      handler(val) {
        if (val.query?.labelId) {
          this.page = 1;
          this.pageUuid = "";
          this.isDetails = false;
          this.sidebarTtemId = this.$route.query.labelId;
          this.getMuBanList(this.sidebarTtemId);
          this.getBankList(this.sidebarTtemId);
        } else {
          this.$refs.elMenu.activeIndex = "-2";
        }
      },
    },
  },
  //方法集合
  methods: {
    memberRenewal(item, event) {
      if (item.isVip == 4) {
        return;
      }
      if (event.target.nodeName != "IMG") {
        return;
      }
      this.$store.commit("setLockClickText", item);
      this.$bus.$emit("buyTipsLock");
      // this.$store.commit("setLockClickText", item.remark);
      // this.$bus.$emit("buyTipsLock");
    },
    // 排序方法
    sortClick(item) {
      this.sortArr.forEach((item) => {
        item.colorSwitch = false;
      });
      item.colorSwitch = !item.colorSwitch;
      item.sort = !item.sort;
      if (item.sort) {
        this.sortVal = "asc";
      } else {
        this.sortVal = "desc";
      }
      this.sortType = item.id;
      this.page = 1;
      this.pageUuid = "";
      this.getMuBanList(this.stair);
    },
    clickOpen(id) {
      try {
        let TokenObj = getToken();
        let access_token = "";
        if (TokenObj) {
          TokenObj = JSON.parse(TokenObj);
          access_token = `${TokenObj.access_token}`;
        }
        // eslint-disable-next-line
        OpenBrowser(
          `https://www.writemall.com/details/${id}?token=${access_token}`
        );
      } catch (e) {
        console.log(e);
      }

      window.isifHaveBuyDialog();
    },
    backClick() {
      this.isDetails = false;
    },
    // 搜索
    search() {
      this.listData = [];
      this.isDetails = false;
      this.page = 1;
      this.pageUuid = "";
      if (this.isMark) {
        this.collectlist();
      } else {
        this.getMuBanList(this.labelId);
      }
    },
    async collectlist() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      const data = {
        page: this.page,
        key: this.searchValue,
        pageUuid: this.pageUuid,
        // sortType: this.sortType,
        // sort: this.sortVal,
      };

      const res = await findUserCollectTemplate(data);
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }

      this.total = res.data.totalNum;
      this.listData = res.data.data;
      this.pageUuid = res.data.page_uuid;
    },
    // 获取侧边栏
    async getBankList(labelId) {
      const data = {
        labelId,
        parentId: 3,
      };
      const res = await getlibrarylist(qs.stringify(data));
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }

      this.bankList = res.data;
      res.data.forEach((item) => {
        if (item.conName == "公文写作") {
          this.stair = item.labelId;
          this.remark = item.remark;
          this.allremark = item.remark;
          this.currentClickItemVip = item;
        }
      });
    },

    goDisk() {
      // console.log("111");
      this.uploadSuccess = false;
      this.$store.commit("ChangeSideBarId", 88);
      this.$router.push("/disk");
      this.$bus.$emit("changeNavIndex", "智能网盘");
    },

    async importChecking(files) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      const { file } = files;
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (suffix != "doc" && suffix != "docx") {
        this.$message({
          type: "error",
          message: "请选择docx或doc文档",
          customClass: "short-message",
          duration: 2000,
        });
        return;
      }
      var fromData = new FormData();
      fromData.append("type", "2");
      fromData.append("file", file);
      const res = await saveToCollect(fromData);
      if (res.code == 200) {
        this.uploadSuccess = true;
        // this.$message({
        //   type: "success",
        //   message: "上传成功",
        //   customClass: "short-message",
        //   duration: 2000,
        // });
      }
    },
    goWorksRank() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.$router.push({
        name: "WorksRank",
        params: {
          index: "1",
        },
      });
    },
    goMyWork() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.$router.push("/copyrightIncome");
    },
    goAuthorRank() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.$router.push({
        name: "AuthorRank",
        params: {
          index: "2",
        },
      });
    },
    goMyAttention() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.$router.push({
        name: "MyAttention",
        params: {
          index: "4",
        },
      });
    },
    async getChildrenList(item, i) {
      this.searchValue = "";
      this.labelId = item.labelId;
      if (item.parentId == 3) {
        this.remark = item.remark;
      }
      this.pageUuid = "";
      // this.labelId = item.labelId;

      this.getMuBanList(item.labelId);
      const data = {
        labelId: item.labelId,
        parentId: 3,
      };

      if (i == 1) {
        this.ChildrenInfo.isShow = true;
        if (this.ChildrenInfo.labelId == item.labelId) {
          return;
        } else {
          this.ChildrenInfo1.isShow = false;
          this.ChildrenInfo2.isShow = false;
          this.ChildrenInfo1.list = [];
          this.ChildrenInfo2.list = [];
        }

        this.ChildrenInfo.parentName = item.conName;
        this.ChildrenInfo.labelId = item.labelId;
      } else if (i == 3) {
        this.ChildrenInfo1.isShow = true;
        if (this.ChildrenInfo1.labelId == item.labelId) {
          return;
        } else {
          this.ChildrenInfo2.isShow = false;
          this.ChildrenInfo2.list = [];
        }

        this.ChildrenInfo1.parentName = item.conName;
        this.ChildrenInfo1.labelId = item.labelId;
      } else if (i == 4) {
        this.ChildrenInfo2.isShow = true;
        if (this.ChildrenInfo2.labelId == item.labelId) {
          return;
        }

        this.ChildrenInfo2.parentName = item.conName;
        this.ChildrenInfo2.labelId = item.labelId;
      }
      if (i == 2) {
        if (item.children.length != 0) {
          return;
        }
      }

      const res = await getlibrarylist(qs.stringify(data));

      if (i == 1) {
        this.ChildrenInfo.list = res.data;
      } else if (i == 3) {
        this.ChildrenInfo1.list = res.data;
      } else if (i == 4) {
        this.ChildrenInfo2.list = res.data;
      }

      if (i == 2) {
        this.$set(item, "children", res.data);
      }
    },

    findBuyState(item) {
      this.stencilClick(item);
    },
    // 获取模板列表
    async getMuBanList(labelId) {
      // console.log(labelId, item.parentId);
      // if (item && item.parentId == 3) {
      //   console.log(1111);
      //
      // }
      this.labelId = labelId;
      const data = {
        page: this.page,
        limit: 15,
        labelId: labelId ? labelId : "",
        key: this.searchValue,
        pageUuid: this.pageUuid,
        findField: this.selectTheme,

        sortType: this.sortType == 1 ? "create_time" : "hot",
        sort: this.sortVal,
      };

      const res = await templateSearch(qs.stringify(data));
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }

      this.total = res.data.totalNum;
      this.listData = res.data.data;
      this.pageUuid = res.data.page_uuid;
    },

    // 收藏
    collectionClick: debounce(async function (val) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });
        return window.loginFun();
      }
      let data = {
        textMd5: val.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
      };
      const res = await addTemplateCollect(data);
      if (res.code == 4) {
        this.$store.commit("setLockClickText", this.currentClickItemVip);
        this.$bus.$emit("buyTipsLock");
        this.$message({
          type: "error",
          message: res.message,
        });
        return;
      }
      if (res.code == 8) {
        this.stencilClick(val);
        this.$message({
          type: "error",
          message: res.message,
        });
        return;
      }
      this.$message({
        type: "success",
        message: val.isMark ? "取消收藏！" : "收藏成功！",
        duration: "1000",
      });
      val.isMark = !val.isMark;
      if (this.isMark) {
        if (this.listData.length == 1 && this.page != 1) {
          this.page--;
        }

        this.collectlist();
      }
      this.$forceUpdate();
    }, 300),

    // 添加模板下载日志
    async Addlog(arr) {
      const res = await logAdd(arr);
    },
    async handlebuyClick() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.noText = "暂无购买";
      this.isMark = false;
      const data = {
        key: this.searchValue,
        sort: this.sortType,
        sortType: this.sortVal == "desc" ? 2 : 1,
      };
      const res = await findUserBuyTemplate(data);
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }

      this.total = res.data.totalNum;
      this.listData = res.data.data;
      if (this.listData.length != 0) {
        this.isflag = false;
      } else {
        this.isflag = true;
      }
    },
    // 热度+1
    async addHot(id) {
      let formData = new FormData();
      formData.append("tempId", id);
      const res = await addTemplateHot(formData);
    },
    // 页码
    currentChange(val) {
      this.$nextTick(() => {
        let PageId = document.querySelector("#main");

        PageId.scrollTop = 0;
      });
      this.page = val;
      if (this.isMark) {
        this.collectlist();
      } else {
        window.document.documentElement.scrollTop = 0;
        this.getMuBanList(this.themeId);
      }
    },
    handleAllClick() {
      console.log(1);
      this.$refs["elMenu"].close(this.stair);
      this.sidebarTtemId = "";
      this.pageUuid = "";
      this.labelId = "";
      this.isMark = false;
      this.stair = "MBgongwxz00001";
      this.searchValue = "";
      this.noText = "更新中";
      this.page = 1;
      this.remark = this.allremark;
      this.currentClickItemVip = this.defaultItem;
      this.getMuBanList();
      this.getBankList("");
      this.ChildrenInfoShow = false;
      this.ChildrenInfo.isShow = false;
      this.ChildrenInfo1.isShow = false;
      this.ChildrenInfo2.isShow = false;
    },
    handleSCClick() {
      this.$refs["elMenu"].close(this.stair);
      this.sidebarTtemId = "";
      this.pageUuid = "";
      this.page = 1;
      this.searchValue = "";
      this.noText = "暂无收藏";
      this.collectlist();
      this.isMark = true;
      this.labelId = "";
      this.ChildrenInfoShow = false;
      this.ChildrenInfo.isShow = false;
      this.ChildrenInfo1.isShow = false;
      this.ChildrenInfo2.isShow = false;
    },
    rightListClick(item) {
      this.stencilClick(item);
    },

    // 模板详情
    async stencilClick(item) {
      // console.log(item);
      this.stencilId = item;
      const data = {
        md5Label: item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
        originalId: item.userId,
        templateId: "",
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      const res = await openTemplateFile(qs.stringify(data));
      loading.close();
      if (res.code == 200) {
        this.$refs["stencilDetails"].htmlText = {
          ...res.data.obj,
          html: res.data.html,
          payStatus: res.data.status,
          url: res.data.url,
          viptip: res.data.viptip,
          author: res.data.author,
          md5Label: item.textMd5 + "/" + this.stair,
          currentClickItemVip: this.currentClickItemVip,
        };
        window.document.documentElement.scrollTop = 0;
        this.isDetails = true;
      } else {
        this.$message.error(res.message);
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getMuBanList();
    if (!this.$route.query?.labelId) {
      this.getBankList("");
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (this.$route.query?.labelId) {
      this.sidebarTtemId = this.$route.query.labelId;
      this.getMuBanList(this.sidebarTtemId);
      this.getBankList(this.sidebarTtemId);
    } else {
      this.$refs.elMenu.activeIndex = "-2";
    }
    this.$bus.$on("LookBuy", () => {
      this.isDetails = false;
      this.$refs["elMenu"].open("zz");
      this.sidebarTtemId = -1;
      this.$refs.elMenu.activeIndex = "-3";
      this.handlebuyClick();
    });

    if (this.$route.params?.searchValue) {
      this.searchValue = this.$route.params?.searchValue;
      this.getMuBanList();
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.blue {
  color: #ff6900;
}
.outBox {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;

  .title_text {
    position: absolute;
    width: 100%;
    top: -70px;
    left: 0;
    z-index: 0;
    .text {
      position: absolute;
      top: 84px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-weight: bold;
      font-size: 34px;
    }

    .banner {
      width: 100%;
    }

    // 搜索
    /* prettier-ignore */
    .search_box {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      // margin: 20px;
      border-radius: 3px;
      position: absolute;
      top: 110px;
      left: 50%;
      height: 40Px;
      transform: translateX(-50%);
      width: 600px;
      background-color: #fff;
      margin: 20px auto;
      .search_left {
        overflow: hidden;
        height: 40Px;
        line-height: 40Px;
        padding-left: 10px;
        .el-select {
          ::v-deep .el-input {
            .el-input__inner {
              width: 100px;
              border: 0px;
              font-size: 13PX;
            }
          }
        }
      }
      .inp {
        height: 26px;
        border-left: 1px solid #e4e4e4;
        padding-left: 20px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-default;
        flex: 1;
      }
      .search_btn {
        height: 40Px;
        padding: 0 10px;
        background: #ff6900;
        @include flex-center();
        // border-radius: 0 5px 5px 0;
        cursor: pointer;
        > img:first-child {
          width: 52px;
          height: 22px;
          margin: 0 10px;
        }
      }
    }

    //
    .tuijian {
      width: 130%;
      position: absolute;
      bottom: -35px;
      display: flex;
      align-items: center;
      color: #fff;
      margin-top: 10px;
      font-size: 14px;

      .tuijian_text {
        margin-right: 10px;
      }

      .list {
        display: flex;
        align-items: center;

        .list_item {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.2);
          padding: 5px;
          margin-right: 10px;
        }
      }
    }
  }
  // .commonFooter_box {
  //   flex: 1;
  // }
  //   width: 100%;
  // position: absolute;
  // left: 0;
  // margin-top: -25px;
  // padding: 25px 0 0 0;
  // background-color: #f9f9f9;
}
.mask_layer {
  position: fixed;
  left: 0;
  top: 51px;
  width: 100%;
  height: calc(100% - 51px);
  opacity: 0.2;
  background: #000;
  z-index: 2000;
}
.pitchOn {
  ::v-deep .el-submenu__title {
    box-sizing: border-box;
    border-right: #ff6900 4px solid;
  }
}
.absolute {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0px;
}
.stencil {
  display: flex;
  position: relative;
  width: 1338px;
  box-sizing: border-box;
  margin: 140px auto 0;
  margin-bottom: 20px;
  .left {
    width: 936px;
    margin-right: 20px;
    background-color: #fff;
    display: flex;
    position: relative;
  }
  .right {
    background-color: #fff;
    flex: 1;
  }
  .perch {
    min-width: 210px;
    height: calc(100vh - 51px);
  }
  .width {
    min-width: 0px !important;
  }
  .disflex {
    display: flex;
  }

  .sidebar_box {
    position: absolute;
    left: 0;
    z-index: 2001;
    background-color: #fff;

    .an_btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      z-index: 10000;
      background-color: #3683f263;
      color: #fff;
      transform: translate(-50%);
      right: -40px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
    }

    .spin {
      transform: translate(-50%) rotate(180deg);
    }
  }

  // 侧边菜单栏
  .sidebar {
    // border-top: solid 1px #e6e6e6;
    height: calc(100vh - 51px);
    overflow-y: auto;
    width: 210px;
    // border-right: solid 1px #e6e6e6;
    .el-menu {
      border-right: 0px;
      .isSubsetClass {
        ::v-deep .el-submenu__title {
          .el-submenu__icon-arrow {
            display: none;
          }
        }
      }
      .charNumClass {
        ::v-deep .el-submenu__title {
          cursor: not-allowed;
          color: #ccc;
        }
        cursor: not-allowed;
        color: #ccc;
      }

      .el-menu-item {
        min-width: 190px;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        -webkit-transition: none;
        transition: none;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        align-items: center;
        white-space: nowrap;
        position: relative;

        .el-icon-arrow-left {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 0px;
          font-size: 14px;
        }
        &:hover {
          color: #ff6900;
          border-right: #ff6900 4px solid;
        }
      }
      .el-menu-item.is-active {
        border-right: #ff6900 4px solid;
        background-color: #ffbf6914;
      }
      ::v-deep .el-submenu {
        .el-submenu__title {
          height: 25px;
          line-height: 25px;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          align-items: center;
          white-space: nowrap;
          .vipIcon {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 40px;
            margin-left: 0px;
          }
          img {
            width: 13px;

            margin-left: 10px;
          }
          &:hover {
            color: #ff6900;
            background-color: #ffbf6914 !important;
          }
        }
        .el-menu {
          .is-active {
            border-right: #ff6900 4px solid;
          }
        }
      }
    }
    ::v-deep .el-submenu {
      .el-submenu__title {
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        align-items: center;
        white-space: nowrap;
        .vipIcon {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 40px;
          margin-left: 0px;
        }
        img {
          width: 13px;

          margin-left: 10px;
        }
        &:hover {
          color: #ff6900;
          background-color: #ffbf6914 !important;
        }
      }
      .el-menu {
        .is-active {
          border-right: #ff6900 4px solid;
        }
      }
    }

    .img1 {
      width: 20px;
      margin-right: 10px;
    }
    .img2 {
      width: 20px;
      margin-left: 10px;
    }
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }

  // 主体区域
  .main {
    // border-top: solid 1px #e6e6e6;
    flex: 1 1 0%;
    // padding: 20px;
    // padding: ;
    box-sizing: border-box;
    // height: calc(100vh - 50px);
    // overflow: auto;
    .banner {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 40px;

      // border: 1px solid #eaeef1;
      background: #fbfcfd;
      .item {
        display: flex;
        align-items: center;
        margin: 0 15px;
        font-size: 14px;
        cursor: pointer;
        color: #333;
        img {
          margin-right: 5px;
          width: 16px;
          height: 16px;
        }
      }
    }

    // 主题
    .theme_box {
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      // padding: 20px 0 0;
      width: 190px;
      height: calc(100vh - 51px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      .title {
        font-weight: bold;
      }
      .theme_lists {
        // border-right: solid 1px #e6e6e6;
        box-sizing: border-box;
      }
      .theme_list {
        font-size: 14px;
        .theme_item {
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          align-items: center;
          white-space: nowrap;
          cursor: pointer;
          height: 25px;
          line-height: 25px;
          padding: 0 0 0 20px;

          &:hover {
            color: #ff6900;
            border-right: #ff6900 4px solid;
            background-color: #ecf5ff;
          }
        }

        .all {
          cursor: pointer;
          position: absolute;
          bottom: 10px;
          right: 5px;
        }

        .selected {
          color: #ff6900;
          background-color: #ecf5ff;
          border-right: #ff6900 4px solid;
        }
      }
    }

    // 排序
    .sort_box {
      padding: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      .right-item {
        display: flex;
        align-items: center;
        margin-right: 40px;
        cursor: pointer;

        .right-item-text {
          font-weight: 500;
        }

        .right-item-icon {
          width: 9px;
          height: 13px;
          margin-left: 6px;
        }

        .up {
          @include backgroundGroup(
            "~assets/images/goldenSentence/sortIcon-blue-up.png"
          );
        }

        .down {
          @include backgroundGroup(
            "~assets/images/goldenSentence/sortIcon-blue.png"
          );
        }
        .download {
          // margin-left: 20px;
          @include backgroundGroup("~assets/images/upload-gray.png");
        }
        .folder {
          margin-left: 20px;
          @include backgroundGroup("~assets/images/folder.png");
        }
        .left-item-icon {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
        .fontColor {
          color: #ff6900;
        }
      }

      .right-item:last-of-type {
        margin-right: 0px;
      }
    }

    // 模板列表
    .stencil_list {
      .stencil_item {
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 15px;
        cursor: pointer;
        position: relative;

        &:hover {
          border-radius: 5px;
          color: #000;
          background-color: #ecf5ff;

          .hover_box {
            right: 5px;
            box-sizing: border-box;
            padding: 5px 0;
            justify-content: space-between;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }

        .img {
          width: 50px;
          margin-right: 20px;
        }
        .imgIcon {
          position: absolute;
          bottom: 15px;
          right: 15px;
          width: 20px;
        }
        .content {
          width: 70%;
          .title {
            font-size: 14px;
            line-height: 1.5;
            font-weight: bold;
            margin-bottom: 15px;
          }
          .obj {
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .hover_box {
          display: none;
          position: absolute;
          right: -90px;

          font-size: 12px;

          .vip {
            padding: 5px;
            border-radius: 5px;
            background-color: #fdb33b;
            color: #fff;
          }
        }
      }
    }

    // 空
    .kong {
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 150px;
        height: 150px;
      }

      .txt {
        margin-top: 30px;
      }
    }

    .el-pagination {
      margin-top: 20px;
      text-align: center;
    }
  }

  // 详情
  .stencilDetails {
    // position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 2003;
    width: 100%;
    // height: calc(100vh - 51px);
  }
}
</style>
